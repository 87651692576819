/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { DragEvent, Fragment, useMemo } from 'react';
import { useLocale } from '../hooks/useLocale';
import { useData } from '../hooks/useData';
import { useSettings } from '../hooks/useSettings';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { SettingsCheckItem } from '../IWidgetProps';
import { useMetadata } from '../hooks/useMetadata';

type JournalSettingsItem = {
  key: string
  label: string
  isHidden: boolean
  isDisabled: boolean
}

export const JournalSettings = () => {
  const { $t } = useLocale()
  const { isLoading } = useData()
  const { metadata } = useMetadata()
  const { localSettingsState, setLocalSettingsState, resetSettingsState, resetGroupsState } = useSettings()

  const columnsState = useMemo<Omit<SettingsCheckItem, 'isActive'>[]>(() => {
    if (!localSettingsState?.columns || !localSettingsState?.locales) return []
    return localSettingsState.order?.reduce<JournalSettingsItem[]>((acc, key) => {
      acc.push({
        key,
        label: $t(key),
        isHidden: localSettingsState.columns?.[key].permanent
          ? false
          : localSettingsState.columns?.[key].hidden || false,
        isDisabled: localSettingsState.columns?.[key].permanent || false
      })

      return acc
    }, []) || []    
  }, [localSettingsState])

  const selectedGroupsState = useMemo<SettingsCheckItem[]>(() => {
    if (!localSettingsState) return []
    return localSettingsState.groups?.reduce<SettingsCheckItem[]>((acc, next) => {
      next.isActive && acc.push({
        key: next.field,
        label: $t(next.field),
        isActive: Boolean(next.isActive),
        isDisabled: Boolean(next.permanent)
      })
      
      return acc
    }, []) || []
  }, [localSettingsState])
  
  const groupState = useMemo<SettingsCheckItem[]>(() => {
    if (!localSettingsState) return []
    const groups = localSettingsState.groups?.length ? localSettingsState.groups : localSettingsState.order
    const result: SettingsCheckItem[] = []

    for (const group of groups) {
      const nextStr = typeof group === 'string' ? group : group.field
      const selectedGroupIndex = selectedGroupsState.findIndex(({ key, isActive }) => (
        isActive && key === nextStr
      ))
      
      if (selectedGroupIndex === -1) {
        result.push({
          key: nextStr,
          label: $t(nextStr),
          isActive: Boolean(localSettingsState.groups?.find(({ field }) => field === group)?.isActive),
          isDisabled: Boolean(localSettingsState.groups?.find(({ field }) => field === group)?.permanent)
        })
      }
    }

    return result;
  }, [localSettingsState, selectedGroupsState])
  
  const activateGroup = (group: string) => {
    const groups = [...localSettingsState.groups || []]
    const order = [...localSettingsState.order]
    const targetGroup = groups.find((el) => el.field === group)

    if (targetGroup) {
      targetGroup.isActive = true
    } else {
      groups.push({ field: group, isActive: true })
    }

    order.splice(groups.filter(({ isActive }) => isActive).length - 1, 0, order.splice(order.indexOf(group), 1)[0])

    setLocalSettingsState({
      ...localSettingsState,
      isGrouped: true,
      columns: {
        ...localSettingsState.columns,
        [group]: {
          ...localSettingsState.columns[group],
          permanent: true
        }
      },
      order,
      groups
    })
  }

  const deactivateGroup = (group: string) => {
    const groups = [...localSettingsState.groups || []]
    const orderCurrent = [...localSettingsState.order]
    const orderDefault = metadata.journal.order

    const targetGroup = groups.find(({ isActive, field }) => isActive && field === group)

    if (targetGroup) {
      targetGroup.isActive = false
    }

    orderCurrent.splice(orderDefault.indexOf(group), 0, orderCurrent.splice(orderCurrent.indexOf(group), 1)[0])

    setLocalSettingsState({
      ...localSettingsState,
      order: orderCurrent,
      isGrouped: groups.some(({ isActive }) => isActive),
      columns: {
        ...localSettingsState.columns,
        [group]: {
          ...localSettingsState.columns[group],
          permanent: Boolean(metadata.journal.columns[group].permanent)
        }
      },
      groups
    })
  }

  const changeOrder = (newPosition: number, oldPosition: number) => {
    const oldOrderKey = localSettingsState.order[newPosition]
    const column = localSettingsState.columns[oldOrderKey]
    !column.permanent && setLocalSettingsState({
      ...localSettingsState,
      order: (() => {
        const arr = [...localSettingsState.order];
        arr.splice(newPosition, 0, arr.splice(oldPosition, 1)[0]);
        return arr
      })()
    })
  }

  const onDrop = (e: DragEvent) => {
    e.preventDefault()
    const newPosition = Number(e.currentTarget.getAttribute('data-index'))
    const oldPosition = Number(e.dataTransfer.getData('text/plain'))
    changeOrder(newPosition, oldPosition)
  }

  const onDragStart = (e: DragEvent) => {
    e.dataTransfer.setData('text/plain', e.currentTarget.getAttribute('data-index') || '');
  }
  
  return (
    <section
      css={settingsCSS}
      style={{
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'all'
      }}
    >
      <header>
        <h3 data-cy="journal-settings-columns-header">{$t('settingsColumnTitle')}</h3>
        <Button
          data-cy="journal-settings-columns-byDefault"
          className="p-button-secondary p-button-outlined p-button-sm"
          icon="mdi mdi-18px mdi-restore p-c"
          tooltip={$t('actions.byDefault')}
          onClick={resetSettingsState}
        />
      </header>
      <ul css={settingsListCSS}>
        {columnsState.map(({ key, label, isHidden, isDisabled }, order) => (
          <li
            key={key}
            data-cy={`journal-settings-column-сheckbox`}
            data-index={order}
            draggable={!isDisabled}
            onDragStart={onDragStart}
            onDragOver={(e) => { e.preventDefault() }}
            onDrop={onDrop}
            className={!isDisabled ? '--draggable' : ''}
          >
            <Checkbox
              inputId={`column:${key}`}
              checked={!isHidden}
              disabled={isDisabled}
              onChange={() => {
                const payload = {
                  [key]: {
                    ...localSettingsState.columns![key],
                    hidden: !isHidden
                  }
                }
                setLocalSettingsState({
                  ...localSettingsState,
                  columns: {
                    ...localSettingsState.columns,
                    ...payload
                  }
                })
              }}
            />
            <label
              htmlFor={`column:${key}`}
              data-cy={`journal-settings-column-сheckbox-${key}`}
              className="ml-2"
              style={{ opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'default' : 'move' }}
            >{label}</label>
          </li>
        ))}
      </ul>
      <Fragment>
        <header>
          <h3 data-cy="journal-settings-groups-header">{$t('settingsGroupsTitle')}</h3>
          <Button
            data-cy="journal-settings-groups-byDefault"
            className="p-button-secondary p-button-outlined p-button-sm"
            icon="mdi mdi-18px mdi-restore p-c"
            tooltip={$t('actions.byDefault')}
            onClick={resetGroupsState}
          />
        </header>
        {selectedGroupsState.length > 0 &&
          <ul css={settingsListCSS}>
            {selectedGroupsState.map(({ key, isDisabled, label }) => (
              <li key={key} data-cy={`journal-settings-selected-group-checkbox`}>
                <Checkbox
                  inputId={`group:${key}`}
                  checked={true}
                  disabled={isDisabled}
                  onChange={() => deactivateGroup(key)}
                />
                <label
                  htmlFor={`group:${key}`}
                  data-cy={`journal-settings-selected-group-checkbox-${key}`}
                  className="ml-2"
                  style={{ opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'default' : 'pointer' }}
                >{label}</label>
              </li>
            ))}
          </ul>
        }
        <ul css={settingsListCSS}>
          {groupState.map(({ key, isDisabled, label }) => (
            <li key={key} data-cy={`journal-settings-group-checkbox`}>
              <Checkbox
                inputId={`group:${key}`}
                checked={false}
                disabled={isDisabled}
                onChange={() => activateGroup(key)}
              />
              <label
                htmlFor={`group:${key}`}
                data-cy={`journal-settings-group-checkbox-${key}`}
                className="ml-2"
                style={{ cursor: 'pointer' }}
              >{label}</label>
            </li>
          ))}
        </ul>
      </Fragment>
    </section>
  )
}

const settingsCSS = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: calc(30rem / var(--bfs)) calc(30rem/var(--bfs)) 0;

  header {
    display: flex;
    align-items: center;
    margin: 0 0 calc(20rem / var(--bfs)) 0;

    h3 {
      margin: 0 var(--spacer-sm) 0 0;
      font-weight: 500;
    }
  }
`

const settingsListCSS = css`
  display: grid;
  list-style: none;
  margin: 0;
  max-width: calc(1024rem / var(--bfs));
  padding: 0 0 calc(30rem / var(--bfs));
  grid-template-columns: repeat(5, auto);

  li {
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0 var(--spacer-xs) var(--spacer) 0;
    position: relative;
    user-select: none;

    label {
      padding-left: var(--form-label-margin);
      white-space: nowrap;
    }

    &.--draggable {
      cursor: move;

      label {
        cursor: move;
      }
    }
  }

  & + ul {
    border-top: 1px solid var(--text-color-secondary);
    padding-top: calc(30rem / var(--bfs));
    margin-top: calc(-30rem / var(--bfs))
  }
`
