/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useMemo } from 'react';
import { useLocale } from '../hooks/useLocale';
import { usePagination } from '../hooks/usePagination';
import { useSearchParams } from '../hooks/useSearchParams';
import { useData } from '../hooks/useData';
import { cleanFilterProps } from '../utils';
import { Button } from 'primereact/button';
import { Paginator, PaginatorProps } from 'primereact/paginator';

type JournalPaginationProps = {
  exportData: () => void
}

export const JournalPagination = ({
  exportData
}: JournalPaginationProps) => {
  const { $t } = useLocale()
  const { pagination } = usePagination()
  const { isLoading, fetchJournalData, fetchJournalDataCount } = useData()
  const { searchParams, setSearchParams } = useSearchParams()

  const RightContent = (
    <div css={contentSetCSS}>
      <Button
        icon="mdi mdi-18px mdi-refresh p-c"
        onClick={() => {
          fetchJournalData()
          fetchJournalDataCount()
        }}
        tooltip={$t('actions.refresh')}
        tooltipOptions={{ position: 'top' }}
      />
      {/* <Button
        icon="mdi mdi-18px mdi-database-export p-c"
        onClick={exportData}
        tooltip={actions.export}
        tooltipOptions={{ position: 'top' }}
      /> */}
    </div>
  )

  const isInactive = useMemo(() => (
    isLoading || pagination.totalRecords === 0
  ), [isLoading, pagination])

  const changePagination = (payload: PaginatorProps) => {
    setSearchParams(cleanFilterProps({
      ...searchParams,
      offset: payload.first,
      limit: payload.rows
    }))
  }

  return (
    <div data-cy={'journal-paginator'}>
      <Paginator
        { ...pagination }
        onPageChange={changePagination}
        template="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        rightContent={RightContent}
        css={rightContentCSS}
        style={{
          opacity: isInactive ? 0.5 : 1,
          pointerEvents: isInactive ? 'none' : 'all'
        }}
      />
    </div>
  )
}

const rightContentCSS = css`
  .p-paginator-right-content {
    margin-right: 0
  }
`

const contentSetCSS = css`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: calc(10rem / var(--bfs));
`
